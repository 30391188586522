<template>
  <ion-page v-touch:swipe.right="onSwipeRight" v-touch:swipe.left="onSwipeLeft">
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Teetime</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-header  :translucent="true" :style="isPlatform('ios') ? 'margin-top: 10px;' : ''">
      <ion-list>
        <ion-item v-if="selectedTeetime" lines="full" class="itemFlight">
          <ion-button v-if="isPlatform('mobile') && !allOpen" class="buttonNoPadding" fill="clear" slot="start" @click="openAll(true)" >
            <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'" color="primary" :md="chevronDownOutline" :ios="chevronDownOutline"></ion-icon>
          </ion-button>
          <ion-button v-if="isPlatform('mobile') && allOpen" class="buttonNoPadding" fill="clear" slot="start" @click="openAll(false)" >
            <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'" color="primary" :md="chevronUpOutline" :ios="chevronUpOutline"></ion-icon>
          </ion-button>
          <ion-button fill="clear" class="buttonNoPadding" slot="start" @click="openCourseHelp()" >
            <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'" color="primary" :md="helpCircleOutline" :ios="helpCircleOutline"></ion-icon>
          </ion-button>
          <ion-button fill="clear" class="buttonNoPadding" slot="start" @click="refresh()" >
           <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'" color="primary" :md="refreshOutline" :ios="refreshOutline"></ion-icon>
          </ion-button>
          <ion-select interface="action-sheet" slot="end" :value="selectedSelection"  @ionChange="itemChanged($event.target.value)">
            <ion-select-option v-for="(item, index) in selectedTeetime.selection" :key="index" :value="item">{{ item.name }}</ion-select-option>          
          </ion-select>
        </ion-item>
        <ion-item lines="full" v-if="$route.params.id">
          <ion-button :title="$t('today')" class="buttonNoPadding" fill="clear" slot="start" @click="goDateToday()" >
            <ion-icon color="primary" :md="todayOutline" :ios="todayOutline" :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'"></ion-icon>
          </ion-button>
          <ion-button class="buttonNoPadding" fill="clear" slot="start" @click="goDateBack()" >
            <ion-icon :md="arrowBackOutline" :ios="arrowBackOutline" :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'"></ion-icon>
          </ion-button>
          <ion-datetime :min="(new Date()).getFullYear() - 1" :max="(new Date()).getFullYear() + 1" slot="start" v-model="selectedDate" :day-short-names="customDayShortNames" display-format="DDD, DD MMM YY" placeholder="Select Date"></ion-datetime>
          <ion-button class="buttonNoPadding" fill="clear" slot="start" @click="goDateForward()" >
          <ion-icon :md="arrowForwardOutline" :ios="arrowForwardOutline" :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'"></ion-icon>
          </ion-button>
          <ion-select interface="popover" slot="end" @ionChange="setFreeSpots()" v-model="selectedNewSlots">
            <ion-select-option value="0">{{ $t('all')}}</ion-select-option>
            <ion-select-option value="1">{{ $t('min1')}}</ion-select-option>
            <ion-select-option value="2">{{ $t('min2')}}</ion-select-option>
            <ion-select-option value="3">{{ $t('min3')}}</ion-select-option>
            <ion-select-option value="4">{{ $t('min4')}}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item v-if="showAddText && (selectedTeetime && (selectedTeetime.dayComment != '' || selectedTeetime.additionalText != '' || selectedTeetime.sunset != ''))">
          <ion-icon style="font-size: 20px;" @click="closeDialog(false)" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
        <ion-grid class="ion-no-padding">
          <ion-row class="ion-no-padding">
            <span v-html="$t('sunsetInfo') + ': ' + selectedTeetime.sunset" class="ion-no-padding ion-no-margin" style="margin-left: 20px; white-space: pre-wrap; color: orange;" v-if="selectedTeetime && selectedTeetime.sunset != ''"></span>
          </ion-row>
          <ion-row class="ion-no-padding">
            <span v-html="selectedTeetime.dayComment" class="ion-no-padding ion-no-margin" style="margin-left: 20px; white-space: pre-wrap;" v-if="selectedTeetime && selectedTeetime.dayComment != ''"></span>
          </ion-row>
          <ion-row>
            <span class="ion-no-padding ion-no-margin" v-if="selectedTeetime && selectedTeetime.additionalText != ''" v-html="selectedTeetime.additionalText" ></span>
          </ion-row>
        </ion-grid>
        </ion-item>
      </ion-list>

    </ion-header>

    <ion-content :fullscreen="true" ref="content">
      <ion-refresher v-if="isPlatform('mobile')" slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
        <h3 v-if="!selectedTeetime && !findTeetimeCourseLoading">{{ $t('noTeetimeCourseFound') }}</h3>
        <ion-grid v-if="selectedTeetime && selectedDate" style="z-index: 999; !important" >
          <ion-row>
            <ion-col v-for="(slot, index) in selectedTeetime.slots" :key="index" size="4" size-xl="4" size-lg="6" size-md="6" size-sm="12" size-xs="12">
              <ion-card :id="(slot.currentSlot ? 'currentSlot' : '')">
                <ion-card-header>
                    <ion-item lines="full" :color="slot.isTeetimeLocked ? 'warning' : ''"  @click="slot.isLocked || !isPlatform('mobile') ? slot.showPlayers : slot.showPlayers = !slot.showPlayers">
                      <ion-icon v-if="isPlatform('mobile') && !slot.isLocked && slot.showPlayers == false" style="zoom: 1.0" slot="start" color="primary" :md="chevronDownOutline" :ios="chevronDownOutline"></ion-icon>
                      <ion-icon v-if="isPlatform('mobile') && !slot.isLocked && slot.showPlayers == true" style="zoom: 1.0" slot="start" color="primary" :md="chevronUpOutline" :ios="chevronUpOutline"></ion-icon>
                      <h3 :style="'color: ' + (slot.isPast ? '' : slot.timeColor) + ';'" slot="start">{{ dayjs(slot.date).tz().format('HH:mm') }}</h3>
                      <h4 v-if="selectedTeetime.greenfeePaymentEnabled || selectedTeetime.showGreenfeePrice" slot="end">{{ getPrice(slot) }} </h4>
                    </ion-item>
                </ion-card-header>
                <ion-card-content overflow-scroll="true" class="has-header" v-if="!slot.isLocked">
                   <ion-list style="padding:0;" v-if="isLoggedIn && !slot.isTeetimeLocked && (slot.showBookingIcon || (!slot.showBookingIcon && !slot.userAllowedToBook && slot.bookingPersons.find(f => f.isMyBooking))) && !slot.isLocked && slot.price >= 0 && !slot.isPast && selectedTeetime.maxPersonsBookable > (slot.bookingPersons.length == 0 ? 0 : slot.bookingPersons.length)">
                    <ion-item lines="none" class="itemFlight">
                      <ion-select slot="start" v-model="selectedFlight" interface="popover" class="ion-no-margin ion-no-padding" >
                        <ion-select-option v-for="(flight, index) in flights" :key="index" :value="flight.id">
                          {{ $t(flight.name) }}
                        </ion-select-option>
                      </ion-select>
                      <ion-button v-if="((selectedTeetime && !selectedTeetime.greenfeePaymentEnabled) || slot.price == 0) && slot.userAllowedToBook" @click="doBooking(slot)" slot="start" style="margin-left: 5px;" class="ion-no-margin ion-no-padding" fill="clear">
                        <ion-icon style="zoom: 1.5; vertical-align: middle;" color="success" :md="downloadOutline" :ios="downloadOutline"></ion-icon>
                      </ion-button>
                      <ion-button @click="editBooking(slot)" style="margin-left: 10px;" slot="start" class="ion-no-margin ion-no-padding" fill="clear">
                        <ion-icon style="zoom: 1.4; vertical-align: middle;" color="primary" :md="colorWandOutline" :ios="colorWandOutline"></ion-icon>
                      </ion-button>
                      <ion-button v-if="!slot.isPast && slot.carts.filter(f => f.isAvailable).length > 0" @click="editBooking(slot)"  slot="end" fill="clear">
                        <ion-icon style="zoom: 1.4; vertical-align: middle;" color="success" slot="end" :md="carOutline" :ios="carOutline"></ion-icon>
                      </ion-button>
                    </ion-item>
                  </ion-list>
                  <ion-list>
                  <div v-if="slot.showPlayers">
                    <ion-item v-for="(n, i) in selectedTeetime.maxPersonsBookable" :key="i" :color="getBookingColor(slot, i)">

                      <ion-icon v-if="selectedTeetime.showAvailableSlots && slot.bookingPersons.length != 0 && slot.bookingPersons.length - 1 >= i && slot.bookingPersons[i].withCart" :md="carOutline" :ios="carOutline" style="zoom: 0.9" color="dangerlight"></ion-icon>
                      <ion-avatar @contextmenu.prevent="" :class="imgClass" @click="changeImg()" v-if="selectedTeetime.showAvailableSlots && slot.bookingPersons.length != 0 && slot.bookingPersons.length - 1 >= i && slot.bookingPersons[i].avatarLink != '' && slot.bookingPersons[i].showAvatar">
                        <img :src="slot.bookingPersons[i].avatarLink">
                      </ion-avatar>
                      <ion-icon v-if="selectedTeetime.showAvailableSlots && slot.bookingPersons.length != 0 && slot.bookingPersons.length - 1 >= i" :color="slot.bookingPersons[i].member == 'member' ? 'member' : 'guest'" :md="getGender(slot.bookingPersons[i].gender)" :ios="getGender(slot.bookingPersons[i].gender)"></ion-icon>
                      <ion-button v-else-if="slot.showBookingIcon && selectedTeetime.showAvailableSlots && !slot.isPast" :disabled="!isLoggedIn" fill="clear" @click="doBooking(slot, true)">
                        <ion-icon style="zoom: 1.4" color="successlight" :md="personAddOutline" :ios="personAddOutline"></ion-icon>
                      </ion-button>
                      <ion-icon v-if="selectedTeetime.showAvailableSlots && slot.bookingPersons.length != 0 && slot.bookingPersons.length - 1 >= i && slot.bookingPersons[i].withDog" :md="pawSharp" :ios="pawSharp" style="zoom: 0.7"></ion-icon>

                      <svg v-if="selectedTeetime.showAvailableSlots && slot.bookingPersons.length != 0 && slot.bookingPersons.length - 1 >= i && slot.bookingPersons[i].with9Hole" width="22" height="30" style="margin-top: 10px;">
                        <circle cx="10" cy="10" r="10" fill="#eb445a" />
                        <text x="45%" y="35%" text-anchor="middle" fill="white" font-size="12px" font-family="Arial" dy=".3em">9</text>
                      </svg>          

                      <ion-icon v-if="selectedTeetime.showAvailableSlots && !slot.isPast &&  slot.bookingPersons.length != 0 && slot.bookingPersons.length - 1 >= i && slot.bookingPersons[i].withPlayWithMe" :md="heartOutline" :ios="heartOutline" color="successlight" style="zoom: 0.8"></ion-icon>
                      <ion-icon @click="deleteBooking(slot, i)" v-if="!slot.isPast && isLoggedIn && selectedTeetime.showAvailableSlots && slot.bookingPersons.length != 0 && slot.bookingPersons.length - 1 >= i && slot.bookingPersons[i].canDelete && (slot.bookingPersons[i].isMyBooking || slot.bookingPersons[i].isBookedByMe)" :md="trashOutline" :ios="trashOutline" color="danger" style="zoom: 0.8"></ion-icon>
                      <ion-label v-if="selectedTeetime.showAvailableSlots && !slot.isPast && (slot.bookingPersons.length == 0 || (i+1) > slot.bookingPersons.length)" style="font-size: 14px; vertical-align: middle;">&nbsp;{{ $t('available') }}</ion-label>
                      <ion-label class="ion-text-wrap" v-else-if="selectedTeetime.showBookedSlots && slot.bookingPersons.length != 0 && slot.bookingPersons.length - 1 >= i" style="font-size: 14px; vertical-align: middle;">&nbsp;{{ slot.bookingPersons[i].lastName }} {{ slot.bookingPersons[i].firstName }} {{ slot.bookingPersons[i].hcpi }}</ion-label>
                    </ion-item>
                  </div>
                  </ion-list>
                  <br>
                  <ion-progress-bar v-if="selectedTeetime.withSlider && slot.isPast" :value="0"></ion-progress-bar>
                  <ion-progress-bar v-else-if="selectedTeetime.withSlider" :color="getPercentage(slot.bookingPersons.length == 0 ? 0 : slot.bookingPersons.length, selectedTeetime.maxPersonsBookable, new Date()).color" :value="getPercentage(slot.bookingPersons.length == 0 ? 0 : slot.bookingPersons.length, selectedTeetime.maxPersonsBookable, new Date()).percentage">3</ion-progress-bar>
                </ion-card-content>
                <ion-card-content v-if="slot.isLocked">
                  <div>
                  <ion-icon style="zoom: 1.5; vertical-align: middle;" color="danger" :md="lockClosedOutline" :ios="lockClosedOutline"></ion-icon>
                  <ion-label style="font-size: 16px; vertical-align: middle;">&nbsp;{{ $t(slot.lockCause) }}</ion-label>
                  </div>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script>

import { IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, toastController, IonIcon,
         IonFab, IonFabButton, IonFabList, IonGrid, IonRow, IonCol, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle,
         IonCardTitle, IonLabel, IonChip, IonList, IonItem, IonTabs, IonTabButton, IonTabBar, IonProgressBar,
         IonSelect, IonSelectOption, IonDatetime, alertController, IonToggle, IonAvatar, isPlatform, loadingController,
         IonMenuButton, IonButtons, IonRefresher, IonRefresherContent,
        } from '@ionic/vue';
import { defineComponent, ref, watch, computed} from 'vue';
import { addOutline, trashOutline, personCircleOutline, personOutline, rocketOutline, optionsOutline,
          chevronBackCircleOutline, personAddOutline, caretDownCircleOutline, swapHorizontalOutline, cartOutline, lockClosedOutline,
          logoEuro, caretUp, maleOutline, femaleOutline, personSharp, pawSharp, returnUpForwardSharp,
          logInOutline, stopCircleOutline, arrowForwardOutline, arrowBackOutline, todayOutline, timeOutline,
          peopleOutline, openOutline, manSharp, womanSharp, refreshOutline, chevronDownOutline, chevronUpOutline,
          helpCircleOutline, chevronDownCircleOutline, carOutline, checkboxOutline, downloadOutline, golfOutline,
          colorWandOutline, heartOutline, closeOutline } from 'ionicons/icons';
import store from '../main'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import * as dayjs from 'dayjs';
require('dayjs/locale/de')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.tz.setDefault('Europe/Paris')
dayjs.locale('de')

import findTeetimeCourseQueryFile from '../graphql/findTeetimeCourse.query.gql'
import getSelectedCourseIDQueryFile from '../graphql/getSelectedCourseID.query.gql'
import lockTeetimeMutationFile from '../graphql/lockTeetime.mutation.gql'
import unlockTeetimeMutationFile from '../graphql/unlockTeetime.mutation.gql'
import createBookingTypeBookingMutationFile from '../graphql/createBookingTypeBooking.mutation.gql'
import deleteBookingTypeBookingMutationFile from '../graphql/deleteBooking.mutation.gql'
import getFlightsByIdQueryFile from '../graphql/getFlightsById.query.gql'
import getFlightPlayersByIdQueryFile from '../graphql/getFlightPlayersById.query.gql'
import HelpCourseDialog from './HelpCourseDialog'
import EditBookingDialog from './dialogs/EditBookingDialog'
import { useRoute, useRouter } from 'vue-router';
import translate from '@/plugins/translate';
import { getClubInfo } from '@/composition'

export default defineComponent({
  name: 'Teetime',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonIcon, IonFab, IonFabButton, IonFabList, IonGrid, IonRow, IonCol, IonCard, IonCardContent, IonCardHeader,
    IonCardTitle, IonCardSubtitle, IonLabel, IonChip, IonList, IonItem, IonTabs, IonTabButton, IonTabBar, IonProgressBar,
    IonSelect, IonSelectOption, IonDatetime, alertController, IonToggle, IonAvatar, IonMenuButton, IonButtons,
    IonRefresher, IonRefresherContent, 
  },
  computed: {
      isSuccessfullBraintreePaypalAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreePaypalAuthorized},
      isSuccessfullBraintreeCreditCardAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreeCreditCardAuthorized },
      isLoggedIn : function() { 
        if (this.isLoggedIn != undefined && this.isLoggedIn != this.$store.getters.isLoggedIn) {
          this.findTeetimeCourseRefetch()
          this.getFlightsByIdRefetch()
          this.selectedFlight = 0
        }
        return this.$store.getters.isLoggedIn
      },
  },
  setup() {
    const router = useRouter()
    let dataLoaded = ref(false)
    let forceSpinner = ref(false)
    const route = useRoute()
    let selectedDate = ref(dayjs.tz(new Date()).format('YYYY-MM-DD'))
    if (route.params.date)
      selectedDate = ref(dayjs.tz(new Date(route.params.date)).format('YYYY-MM-DD'))
    const selectedSlot = ref('')
    let bookingOnline = ref(false)
    let selectedNewSlots = ref('0')
    const showAddText = ref(true)
    const imgClass = ref('playerImg')
    const allOpen = ref(!isPlatform('mobile'))
    const clubInfo = ref(getClubInfo())

    let loadingSpinner = null
    const customDayShortNames = [
      translate('sunday'),
      translate('monday'),
      translate('tuesday'),
      translate('wednesday'),
      translate('thursday'),
      translate('friday'),
      translate('saturday')
    ];
    const selectedFlight = ref(0)

    const content = ref();
    const contentToScroll = ref(400)
    const currentSlot = ref();

    const { refetch: findTeetimeCourseRefetch, result: findTeetimeCourseResult, loading: findTeetimeCourseLoading } = useQuery(findTeetimeCourseQueryFile, () => ({ id: parseInt(route.params.id),  date: dayjs(selectedDate.value).format('YYYY-MM-DD'), isMobile: isPlatform('mobile'), freeSpots: parseInt(selectedNewSlots.value), minHour: 0, maxHour: 0 }), () => ({ fetchPolicy: 'no-cache', enabled:  route.params.id != null }))
    const selectedTeetime = useResult(findTeetimeCourseResult, null , data => data.findTeetimeCourse.bookingType)
    const selectedSelection = useResult(findTeetimeCourseResult, null , data => data.findTeetimeCourse.bookingType.selection.find(f => f.courseid == route.params.id) == null ? data.findTeetimeCourse.bookingType.selection.find(f => f.courseid == route.params.id-1) : data.findTeetimeCourse.bookingType.selection.find(f => f.courseid == route.params.id))

    const { refetch: getSelectedCourseIDRefetch, result: getSelectedCourseIDResult } = useQuery(getSelectedCourseIDQueryFile, () => ({ date: dayjs(selectedDate.value).format('YYYY-MM-DD') }), () => ({ fetchPolicy: 'no-cache', enabled:  route.params.id != null }))
    const selectedCourseID = useResult(findTeetimeCourseResult, null , data => data.getSelectedCourseID.courseID)

    const { result: getFlightsByIdResult, refetch: getFlightsByIdRefetch } = useQuery(getFlightsByIdQueryFile, () => ({ id: '' }), () => ({ enabled: route.params.id != null, fetchPolicy: 'no-cache'}))
    const flights = useResult(getFlightsByIdResult, null, data => data.getFlightsById.flights)

    const { result: getFlightPlayersByIdResult, refetch: getFlightPlayersByIdRefetch } = useQuery(getFlightPlayersByIdQueryFile, () => ({ id: selectedFlight.value, bookingTypeId: parseInt(route.params.id), slotDate: selectedSlot.value, checkPayment: true }) , () => ({ enabled: route.params.id != null, fetchPolicy: 'no-cache' }))
    const flightPlayers = useResult(getFlightPlayersByIdResult, null, data => data.getFlightPlayersById.flightPlayers)

    const { mutate: createBookingTypeBookingMutation } = useMutation(createBookingTypeBookingMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: deleteBookingTypeBookingMutation } = useMutation(deleteBookingTypeBookingMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: lockTeetimeMutation } = useMutation(lockTeetimeMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: unlockTeetimeMutation } = useMutation(unlockTeetimeMutationFile, { fetchPolicy: 'no-cache' });

    watch(
      () => route.params.date,
      (date, prevId) => {
        if (route.path.startsWith('/teetime/') && date != undefined)
          selectedDate.value = dayjs.tz(new Date(route.params.date)).format('YYYY-MM-DD')
          forceSpinner.value = true
      }
    )

    watch(selectedSelection, async (newLoading, prevLoading)=> {
      if (newLoading && newLoading.courseid && newLoading.courseid != route.params.id)
        router.push('/teetime/' + newLoading.courseid + '/' + route.params.date)
    })

    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(async ()  => { 
        if (!findTeetimeCourseLoading.value && loadingSpinner != null) {
          loadingSpinner.dismiss() 
          loadingSpinner = null
          if (!dataLoaded.value)
            setTimeout(() => { var currentSlot = document.getElementById('currentSlot'); if (currentSlot) content.value.$el.scrollToPoint(0, currentSlot.parentElement.offsetTop - currentSlot.offsetTop - currentSlot.parentElement.parentElement.offsetTop - currentSlot.parentElement.parentElement.parentElement.offsetTop) }, 1000)
          dataLoaded.value = true
          forceSpinner.value = false
        }
      })
    }).catch(err => { })

    watch(findTeetimeCourseLoading, async (newLoading, prevLoading)=> {
      if (!newLoading && loadingSpinner != null) {
          loadingSpinner.dismiss()
          loadingSpinner = null
          if (!dataLoaded.value) {
            setTimeout(() => { var currentSlot = document.getElementById('currentSlot'); if (currentSlot) content.value.$el.scrollToPoint(0, currentSlot.parentElement.offsetTop - currentSlot.offsetTop - currentSlot.parentElement.parentElement.offsetTop - currentSlot.parentElement.parentElement.parentElement.offsetTop) }, 1000)
          }
          dataLoaded.value = true
          forceSpinner.value = false
          return
      }
      else if (newLoading && (!dataLoaded.value || forceSpinner.value)) {
        loadingController.create().then(res => {
              showAddText.value = true
              loadingSpinner = res
              loadingSpinner.present().then(async () => { 
                if (!findTeetimeCourseLoading.value) {
                  loadingSpinner.dismiss() 
                  loadingSpinner = null
                  if (!dataLoaded.value)
                    setTimeout(() => { var currentSlot = document.getElementById('currentSlot'); if (currentSlot) content.value.$el.scrollToPoint(0, currentSlot.parentElement.offsetTop - currentSlot.offsetTop - currentSlot.parentElement.parentElement.offsetTop - currentSlot.parentElement.parentElement.parentElement.offsetTop) }, 1000)
                  dataLoaded.value = true
                  forceSpinner.value = false
                }
              })
            }).catch(err => { })
      }
    })

    return {
      createBookingTypeBookingMutation, deleteBookingTypeBookingMutation, getFlightsByIdResult,
      findTeetimeCourseRefetch, findTeetimeCourseResult,
      personCircleOutline, chevronBackCircleOutline, trashOutline, personAddOutline, caretDownCircleOutline,
      swapHorizontalOutline, addOutline, cartOutline, logoEuro, lockClosedOutline, findTeetimeCourseLoading, selectedDate,
      personOutline, rocketOutline, optionsOutline, caretUp, helpCircleOutline,
      bookingOnline, maleOutline, femaleOutline, personSharp, pawSharp, returnUpForwardSharp,
      logInOutline, stopCircleOutline, arrowForwardOutline, arrowBackOutline,todayOutline,
      selectedNewSlots, loadingSpinner, route, timeOutline,  dayjs, selectedSlot, heartOutline,
      customDayShortNames, content, contentToScroll, currentSlot, peopleOutline, openOutline,
      scrollToBottom: () => content.value.$el.scrollToBottom(500), flights, selectedFlight, manSharp, womanSharp,
      refreshOutline, chevronDownCircleOutline, isPlatform, lockTeetimeMutation, unlockTeetimeMutation, showAddText,
      carOutline, checkboxOutline, downloadOutline, forceSpinner, flightPlayers, getFlightPlayersByIdRefetch, closeOutline, imgClass, allOpen,
      getFlightsByIdRefetch, selectedTeetime, chevronDownOutline, chevronUpOutline, dataLoaded, selectedSelection, golfOutline, colorWandOutline, clubInfo, getSelectedCourseIDRefetch, selectedCourseID
    }
  },
  methods: {
    onSwipeRight() {
      this.goDateBack()
	  },
    onSwipeLeft() {
      this.goDateForward()
	  },
    openAll(value) {
      for (const slot of this.selectedTeetime.slots) {
        slot.showPlayers = value
      }
      this.allOpen = value
    },
    changeImg() {
      if (this.imgClass == 'playerImg')
        this.imgClass = 'playerImgBig'
      else
        this.imgClass = 'playerImg'
    },
    closeDialog(value) {
      this.showAddText = value
    },
    getGender(gender) {
      if (gender == "")
        return this.personOutline
      else if (gender == "male")
        return this.manSharp
      else
        return this.womanSharp
    },
    async doRefresh(event) {
      event.target.complete();
      await this.findTeetimeCourseRefetch()
    },
    itemChanged(value) {
      if (value != undefined && value.courseid != this.selectedSelection.courseid) {
        this.forceSpinner = true
        this.dataLoaded = false
        this.$router.push('/teetime/' + value.courseid + '/' + dayjs.tz(this.selectedDate).format('YYYY-MM-DD'))
      }
    },
    refresh() {
      this.forceSpinner = true
      this.findTeetimeCourseRefetch()
    },
    async deleteBooking(slot, pos) {
      this.deleteBookingTypeBookingMutation({ dateSlot: slot.date, position: slot.bookingPersons[pos].uniqueId, bookingTypeId: this.$route.params.id, language: this.$store.getters.language })
      .then(res => {
          this.findTeetimeCourseRefetch()
          toastController
            .create({
              message: this.$t('bookingDeleted'),
              duration: 3000,
              color: 'warning'
            }).then(res => res.present())
      })
      .catch(err => {
          toastController
            .create({
              message: this.$t('notSuccessful') + ': ' + err,
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
      }) 

    },
    async openCourseHelp() {
      const modal = await modalController
        .create({
          component: HelpCourseDialog,
          componentProps: {
            propsData: {
              selectedTeetimeId: this.$route.params.id,
              selectedDate: this.selectedDate,
            }
          },
        })
      return modal.present();
    },
    setId(slot) {
      if(slot.currentSlot) {
        return "currentSlot"
      }
    },
    setFreeSpots() {
      this.forceSpinner = true
    },
    goDateToday() {
      this.forceSpinner = true
      this.getSelectedCourseIDRefetch( { date: dayjs.tz(new Date()).format('YYYY-MM-DD') })
      .then(res => {
        const courseID = res.data.getSelectedCourseID.courseID == 0 ?  this.$route.params.id : res.data.getSelectedCourseID.courseID
        this.$router.push('/teetime/' + courseID + '/' + dayjs.tz(new Date()).format('YYYY-MM-DD'))
        this.selectedDate = dayjs.tz(new Date()).format('YYYY-MM-DD')
      })
      .catch(err => {
        this.$router.push('/teetime/' + this.$route.params.id + '/' + dayjs.tz(new Date()).format('YYYY-MM-DD'))
        this.selectedDate = dayjs.tz(new Date()).format('YYYY-MM-DD')
      }) 
    },
    goDateBack() {
      this.forceSpinner = true
      this.getSelectedCourseIDRefetch( { date: dayjs.tz(this.selectedDate).subtract(1, 'day').format('YYYY-MM-DD') })
      .then(res => {
        const courseID = res.data.getSelectedCourseID.courseID == 0 ?  this.$route.params.id : res.data.getSelectedCourseID.courseID
        this.$router.push('/teetime/' + courseID + '/' + dayjs.tz(this.selectedDate).subtract(1, 'day').format('YYYY-MM-DD'))
        this.selectedDate = dayjs.tz(this.selectedDate).subtract(1, 'day').format('YYYY-MM-DD')
      })
      .catch(err => {
        this.$router.push('/teetime/' + this.$route.params.id + '/' + dayjs.tz(this.selectedDate).subtract(1, 'day').format('YYYY-MM-DD'))
        this.selectedDate = dayjs.tz(this.selectedDate).subtract(1, 'day').format('YYYY-MM-DD')
      }) 
    },
    goDateForward() {
      this.forceSpinner = true
      this.getSelectedCourseIDRefetch( { date: dayjs.tz(this.selectedDate).add(1, 'day').format('YYYY-MM-DD') })
      .then(res => {
        const courseID = res.data.getSelectedCourseID.courseID == 0 ?  this.$route.params.id : res.data.getSelectedCourseID.courseID
        this.$router.push('/teetime/' + courseID + '/' + dayjs.tz(this.selectedDate).add(1, 'day').format('YYYY-MM-DD'))
        this.selectedDate = dayjs.tz(this.selectedDate).add(1, 'day').format('YYYY-MM-DD')
      })
      .catch(err => {
        this.$router.push('/teetime/' + this.$route.params.id + '/' + dayjs.tz(this.selectedDate).add(1, 'day').format('YYYY-MM-DD'))
        this.selectedDate = dayjs.tz(this.selectedDate).add(1, 'day').format('YYYY-MM-DD')
      }) 
    },
    getBookingColor(slot, key) {
      if (slot.isPast)
        return ""
      if (!slot.isPast && slot.bookingPersons.length > 0 && slot.bookingPersons.length - 1 >= key && slot.bookingPersons[key].isMyBooking)
        return "success"
      return ""
    },
    async editBooking(slot, doRefetch = true) {
      //this.selectedSlot = slot.date
      await this.getFlightPlayersByIdRefetch({ id: this.selectedFlight, bookingTypeId: parseInt(this.$route.params.id), slotDate: slot.date, checkPayment: true } )

      if (!this.flightPlayers || (this.flightPlayers && this.flightPlayers.length <= 0 )) {
          toastController
            .create({
              message: this.$t('noPlayers'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
          return;
      }

      let pricesum = parseFloat(slot.price)
      if (this.flightPlayers) {
        pricesum = this.flightPlayers.map(p => p.price).reduce((price, p) => p + price)
      }

      let play18Holes = true
      if (this.clubInfo.clubInfo.askTeetimeNumHoles == true) {
        const confirmation = await this.warn()
        if (!confirmation) play18Holes = false
        for (const player of this.flightPlayers) {
          player.show9Hole = !confirmation
        }

      }

      this.lockTeetimeMutation({ dateSlot: slot.date, bookingTypeId: this.$route.params.id})
      .then(async res  => {
        if (res.data.lockTeetime.success) {
          const modal = await modalController
            .create({
              component: EditBookingDialog,
              cssClass: this.isPlatform('mobile') ? '' : 'editBooking-modal',
              componentProps: {
                propsData: {
                  slot: slot,
                  bookingTypeId: this.$route.params.id,
                  flightPlayers: this.flightPlayers,
                  selectedTeetime: this.selectedTeetime,
                  play18Holes: play18Holes
                }
              },
            })
          modal.onDidDismiss()
            .then((data) => {
              this.getFlightPlayersByIdRefetch()
              if (data.data) {
                  this.findTeetimeCourseRefetch()
              }
              else
                this.unlockTeetimeMutation({ dateSlot: slot.date, bookingTypeId: this.$route.params.id})
            })

          return modal.present();
        }
      })
      .catch(err => {
          toastController
            .create({
              message: this.$t('notSuccessful') + ': ' + err,
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
      }) 

    },
    async warn() {
      return new Promise(async (resolve) => {
        const confirm = await alertController.create({
          header: translate('ask9holes'),
          message: translate('ask9holesText'),
          cssClass: 'custom-alert',
          buttons: [
            {
              text: translate('9holes'),
              handler: () => {
                return resolve(false);
              },
            },
            {
              text: translate('18holes'),
              handler: () => {
                return resolve(true);
              },
            },
            {
              text: this.$t('cancel') ,
              role: 'cancel',
            },
          ],
        });

        await confirm.present();
    });
    },
    async doBooking(slot, justMe = false) {

      if (!this.flightPlayers || (this.flightPlayers && this.flightPlayers.length <= 0 )) {
          toastController
            .create({
              message: this.$t('noPlayers'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
          return;
      }

      // get price for all players
      if (this.selectedTeetime.greenfeePaymentEnabled) {
        let pricesum = parseFloat(slot.price)
        if (this.flightPlayers) {
          await this.getFlightPlayersByIdRefetch({ id: this.selectedFlight, bookingTypeId: parseInt(this.$route.params.id), slotDate: slot.date } )
          const filteredPlayers = this.flightPlayers.filter(f => f.payGreenfee)
          if (filteredPlayers && filteredPlayers.length > 0) 
            pricesum = this.flightPlayers.map(p => p.price).reduce((price, p) => p + price)
        }

        if ((justMe && parseFloat(slot.price) > 0) || pricesum > 0) {
          this.editBooking(slot, false)
          return
        }
      }
      let slotJson = slot
      let players = JSON.stringify(this.selectedFlight)
      if (justMe)
        players = JSON.stringify(this.flights[0].id)

      let play18Holes = true
      if (this.clubInfo.clubInfo.askTeetimeNumHoles == true) {
        const confirmation = await this.warn()
        if (!confirmation) play18Holes = false
      }

      this.createBookingTypeBookingMutation({ dateSlot: slotJson.date, bookingTypeId: this.$route.params.id, players: players, useFlightIds: true, language: this.$store.getters.language, play18Holes: play18Holes })
      .then(res => {
          this.findTeetimeCourseRefetch()
          toastController
            .create({
              message: this.$t('bookingAdded'),
              duration: 3000,
              color: 'success'
            }).then(res => res.present())
      })
      .catch(err => {
          /*toastController
            .create({
              message: this.$t('notSuccessful') + ': ' + err,
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())*/
      }) 

    },
    getPrice(slot) {
      let slotJson = slot
      if (slotJson.isLocked || slotJson.isPast)
        return ''
      else
        return slotJson.price + " " + slotJson.currencySymbol
    },
    goBack() {
      this.$router.go(-1)
    },
    getPercentage (bookedPlayers, maxPlayers, datetime) {
      const percentage = (bookedPlayers / maxPlayers) == 0 ? 1: bookedPlayers / maxPlayers
      let color = "successlight"
      if (bookedPlayers == maxPlayers)
        color = "danger"
      else if (percentage > 0 && percentage <= 0.25)
        color = "warninglight"
      else if (percentage > 0.25 && percentage <= 0.5)
        color = "warning"
      else if (percentage > 0.5 && percentage <= 0.75)
        color = "dangerlight"
      else if (percentage > 0.75 && bookedPlayers != 0)
        color = "danger"

      return { percentage: percentage, color: color }
    },
  }
});

</script>

<style scoped>

.playerImg {
  width: 40px; 
  height: 40px;
}

.playerImgBig {
  width: 120px; 
  height:120px
}

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.item .item-content {
  background-color: green !important;
}

.itemFlight {
    --background-hover: unset;
}

ion-icon:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
}

.buttonNoPadding {
  --padding-end: 0px;
  --inner-padding-end: 0px;
  margin-inline: 0px 15px;
}

</style>

<style lang="scss">
.card-center {
    width:90%; // adjust width on your ion-card suitable on your screen
    display:block;
    margin: 0 auto;
}

.custom-alert button.alert-button-group {
    width: 33.33%;
}

.custom-alert .alert-button-group .button-inner {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.custom-alert .alert-button-group-vertical {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row ;
}
//.alert-wrapper {
//width: 100% !important;
//max-width: 100% !important;
//}

</style>